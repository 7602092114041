import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"
import BackgroundImage from "gatsby-background-image"

const PartnersSection = styled.section`
  ${tw`mb-22 overflow-visible md:mb-24 xl:mb-36`}

  .section-title {
    ${tw`md:pb-20 xl:pb-12`}
    max-width: 555px
  }

  .partner-section-inner {
    ${tw`bg-grey-lighter`}
  }

  .content-width-wrap {
    ${tw`max-w-xl mx-auto px-18 pt-50 md:px-40 xl:px-58 xl:pt-82`}
  }

  .partner-logos-row {
    ${tw`sm:flex sm:flex-wrap sm:justify-center sm:-mx-4 lg:flex-no-wrap xl:justify-between`}
    transform: translateY(45px);

    @media (min-width: 1280px) {
      transform: translateY(70px);
    }
  }

  .partner-logo-wrap {
    box-shadow: 0px 10px 40px #0000001A;
    ${tw`mx-auto mb-25 h-54 w-96 sm:mx-4 sm:mb-34 lg:mb-0`}

    :last-child {
      ${tw`mb-0`}
    }

    :nth-last-child(-n+2) {
      ${tw`sm:mb-0`}
    }

    @media (min-width: 1280px) {
      width: 210px;
      height: 139px;
    }
  }

  .partner-logo {
    ${tw`bg-no-repeat bg-cover bg-center h-full`}
  }
`

const Partners = ({ subTitle, mainTitle, partnersLogos }) => {
  return (
    <PartnersSection className="section-gap-sm xl:section-gap-xl">
      <div className="partner-section-inner">
        <div className="content-width-wrap">
          <div className="section-title">
            <h5 className="section-sub-title sm:section-sub-title-lg">
              {subTitle}
            </h5>
            <h2 className="section-main-title md:section-main-title-md">{mainTitle}</h2>
          </div>

          <div className="partner-logos-row">
            {partnersLogos.map((item, index) => (
              <div key={index} className="partner-logo-wrap">
                <BackgroundImage
                  tag="div"
                  className="partner-logo"
                  fluid={
                    item.aboutUsPartnerImage.localFile.childImageSharp.fluid
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </PartnersSection>
  )
}

export default Partners
