import React from "react"
import SEO from "components/seo"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SubBanner from "../components/SubBanner"
import AboutIntro from "../components/AboutIntro"
import Timeline from "../components/Timeline"
import TwoColumn from "../components/TwoColumn"
import Partners from "../components/Partners"
import Testimonials from "../components/Testimonials"
import CTA from "../components/CTA"

export const query = graphql`
  query AboutUs {
    page: allWpPage(filter: { slug: { eq: "about-us" } }) {
      edges {
        node {
          nodeType
          pageTitle: title
          acfSubPageBanner {
            subPageBannerImage {
              localFile {
                childImageSharp {
                  fluid(quality: 90, maxWidth: 1920) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
            }
            sloganText: subPageBannerSloganText
            sloganTitle: subPageBannerSloganTitle
          }
          acfCta {
            ctaShow
            ctaText
            ctaLink1 {
              target
              title
              url
            }
            ctaLink2 {
              target
              title
              url
            }
          }
          acfTestimonials {
            testimonialShow
            testimonialCard {
              testimonialAuthorCompany
              testimonialAuthorName
              testimonialContent
              testimonialImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 325) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          acfAboutus {
            introductionContent: aboutUsIntroductionContent
            title: aboutUsTitle
            tagline: aboutUsTitleTagline
            orangeboxes: aboutUsIntroductionOrangeBoxes {
              fieldGroupName
              content: orangeBoxContent
              number: orangeBoxNumber
            }
            ourStoryTitle
            ourStoryTitleTagline
            ourStoryDescription
            timeline: ourStoryContent {
              year
              yearAchievement
            }
            ourPartnersTitle
            ourPartnersTitleTagline
            ourPartnersBoxes {
              aboutUsPartnerImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 1920) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
            twoColumnRow {
              description: twoColDescriptionContent
              mainTitle: twoColMainTitle
              subTitle: twoColSubTitle
              buttonTextLink: twoColButtonTextLink {
                target
                title
                url
              }
              twoColImage {
                localFile {
                  childImageSharp {
                    fluid(quality: 90, maxWidth: 680) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }
          }
          ...SEO
        }
      }
    }
  }
`

const AboutUsPage = ({ location, data }) => {
  const {
    acfSubPageBanner,
    acfAboutus,
    acfCta,
    acfTestimonials,
    seo,
  } = data.page.edges[0].node

  return (
    <Layout path={location.pathname}>
      <SEO data={seo} />

      <SubBanner
        mainTitle={acfSubPageBanner.sloganTitle}
        subTitle={acfSubPageBanner.sloganText}
        image={acfSubPageBanner.subPageBannerImage.localFile.childImageSharp.fluid}
      />

      <AboutIntro
        subTitle={acfAboutus.tagline}
        mainTitle={acfAboutus.title}
        description={acfAboutus.introductionContent}
        orangeBoxes={acfAboutus.orangeboxes}
      />

      <Timeline
        subTitle={acfAboutus.ourStoryTitleTagline}
        mainTitle={acfAboutus.ourStoryTitle}
        description={acfAboutus.ourStoryDescription}
        data={acfAboutus.timeline}
      />

      <TwoColumn data={acfAboutus.twoColumnRow} />

      <Partners
        subTitle={acfAboutus.ourPartnersTitleTagline}
        mainTitle={acfAboutus.ourPartnersTitle}
        partnersLogos={acfAboutus.ourPartnersBoxes}
      />

      {acfTestimonials.testimonialShow && (
        <Testimonials data={acfTestimonials.testimonialCard} />
      )}

      {acfCta.ctaShow && (
        <CTA
          text={acfCta.ctaText}
          buttonOne={acfCta.ctaLink1}
          buttonTwo={acfCta.ctaLink2}
        />
      )}
    </Layout>
  )
}

export default AboutUsPage
