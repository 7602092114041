import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

const OrangeBoxRow = styled.div`
  ${tw`md:flex md:justify-center`}

  .box-item {
    ${tw`text-center uppercase mb-18 mx-auto md:mr-8 md:mb-0 xl:mr-40`}
    background: transparent linear-gradient(180deg, #F26B24 0%, #FACA11 100%) 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 40px #00000026;
    border-radius: 50px 0px;
    max-width: 340px;

    :last-child {
      ${tw`md:mr-0`}
    }
  }

  .box-item-inner {
    ${tw`px-16 pt-16 pb-14 md:px-10 md:py-16`}

    p {
      ${tw`text-base text-white px-4 leading-6`}
    }

    h5 {
      ${tw`text-5xl md:text-4xl xl:text-5xl text-white font-bold font-fb`}
    }
  }

`

const OrangeBox = ({ data }) => {
  return (
    <OrangeBoxRow>
      { data.map((item, index) => (
        <div key={index} className="box-item">
          <div className="box-item-inner">
            <p>{item.content}</p>
            <h5>{item.number}</h5>
          </div>
        </div>
      )) }
    </OrangeBoxRow>
  )
}

export default OrangeBox
