import React from "react"
import styled from "styled-components"
import tw from "tailwind.macro"

import SectionTitle from "../SectionTitles"
import TimelineCircle from "../../assets/timeline-circle.svg"

const TimelineSection = styled.section`
  ${tw`max-w-xl mx-auto px-18 md:px-40`}

  .timeline-content {
    ${tw`mx-auto pt-34 md:pt-44 xl:pt-48`}
    max-width: 665px;
  }

  .leaf-item-wrap {
    ${tw`relative md:w-2/4`}


    :last-child {
      ${tw`md:pb-28`}

      :after {
        ${tw`hidden md:block absolute`}
        content: url(${TimelineCircle});
        bottom: -20px;
      }

        :not(:nth-child(odd)) {
            :after {
                left: -15px;
            }
        }

        :not(:nth-child(even)) {
            :after {
                right: -15px;
            }
        }
    }


    :before {
      content:"";
      ${tw`hidden md:block bg-secondary-success absolute bottom-0 h-full`}
      width: 3px;
    }

    .leaf-item {
      ${tw`pt-17 md:relative md:pt-0 md:bg-secondary-success`}

      @media (min-width: 768px) {
        min-height: 200px;
      }

      .leaf-item-content {
        p,
        h5 {
          ${tw`md:text-white`}
        }
      }
    }

    :first-child {
      ${tw`md:pt-4`}
      margin-top: 0 !important;

      .leaf-item {
        ${tw`pt-0 md:bg-secondary-success`}
      }

      :after {
        ${tw`hidden md:block absolute`}
        top: -20px;
        right: -15px;
        content: url(${TimelineCircle});
      }

      :before {
        background-color: #0aa54a !important;
        ${tw`z-10`}
      }
    }

    :nth-child(odd) {
        ${tw`md:-ml-2 xl:-mt-18`}

      .leaf-item {
        ${tw`text-left pr-50 md:pr-0 md:-ml-2`}
        border-top-right-radius: 65% 100%;
        border-bottom-left-radius: 65% 100%;

        .leaf-item-content {
          @media (min-width: 768px) {
            padding: 40px 90px 40px 80px
          }

          @media (min-width: 1280px) {
            padding: 35px 105px 55px 85px;
          }
        }

        h5 {
          right: 30px;
        }
      }

      :before {
        right: -7px;
      }
    }

    :nth-child(even) {
      ${tw`md:-mr-3 md:ml-auto md:-mt-44 xl:-mt-34`}

      .leaf-item {
        ${tw`text-right pl-50 md:pl-0`}
        border-top-left-radius: 65% 100%;
        border-bottom-right-radius: 65% 100%;

        .leaf-item-content {
          @media (min-width: 768px) {
            padding: 40px 65px 50px 115px
          }

          @media (min-width: 1280px) {
            padding: 35px 65px 50px 130px;
          }
        }

        h5 {
          left: 30px;
        }
      }

      :before {
        left: -6px;
      }
    }
  }

  .leaf-item-content {
    ${tw`text-black-dark md:text-left`}

    @media (min-width: 768px) {
      min-height: 160px;
    }

    @media (min-width: 1280px) {
      min-height: 200px;
    }

    h5 {
      ${tw`text-2xl font-fb mb-2 md:absolute`}

      @media (min-width: 768px) {
        bottom: 15px;
      }
    }

    p {
      ${tw`leading-7 font-medium`}
    }
  }

  .section-titles {
    @media (max-width: 640px) {
      ${tw`text-left`}

      h5 {
        :before { 
          ${tw`m-0`}
        }
      }
    }
  }
`

const Timeline = ({ data, subTitle, mainTitle, description }) => {
  const dataTree = data.slice().reverse()
  return (
    <TimelineSection className="section-gap-sm xl:section-gap-xl">
      <SectionTitle
        subTitle={subTitle}
        mainTitle={mainTitle}
        description={description}
      />
      <div className="timeline-content">
        {dataTree.map(item => (
          <div key={item.year} className="leaf-item-wrap">
            <div className="leaf-item">
              <div className="leaf-item-content">
                <h5>{item.year}</h5>
                <p>{item.yearAchievement}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </TimelineSection>
  )
}

export default Timeline
