import React from "react"
import PropTypes from 'prop-types'
import OrangeBox from "../OrangeBox"
import styled from "styled-components"
import tw from "tailwind.macro"

const AboutIntroSection = styled.section`
  ${tw`bg-grey-lighter mb-50 overflow-visible`}

  .about-intro-inner {
    ${tw`max-w-xl mx-auto px-18 pt-50 md:px-40 xl:px-58 xl:pt-76`}
  }

  .about-intro-row {
    ${tw`xl:flex xl:justify-between xl:flex-wrap`}
  }

  .col {
    p {
      ${tw`text-black font-medium`}
    }

    &.col-left {
      max-width: 555px;
    }

    &.col-right {
      max-width: 440px;
      ${tw`mt-30 xl:mt-0`}
    }
  }

  .orange-box-wrap {
    ${tw`-mt-8 xl:-mt-2`}
    transform: translateY(90px);
  }
`

const AboutIntro = ({ orangeBoxes, subTitle, mainTitle, description }) => {
  return (
    <AboutIntroSection>
      <div className="about-intro-inner">
        <div className="about-intro-row">
          <h5 className="section-sub-title sm:section-sub-title-lg">
            {subTitle}
          </h5>
          <div className="col col-left">
            <h2 className="section-main-title md:section-main-title-md">{mainTitle}</h2>
          </div>
          {description && (
            <div className="col col-right">
              <p>{description}</p>
            </div>
          )}
        </div>
        <div className="orange-box-wrap">
          <OrangeBox data={orangeBoxes} />
        </div>
      </div>
    </AboutIntroSection>
  )
}

AboutIntro.propTypes = {
  subTitle: PropTypes.string,
}

export default AboutIntro
